import { api, createCrudService } from '@cargill/shared'

const service = createCrudService(
  '/api/controltower/stuffingPortalCompanies',
  api,
  {
    importLogUrl: '/api/controltower/importLog'
  }
)

export default service
