<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :details="details"
      :metadata="metadata"
      translatePrefix="controlTower.pages"
    />
  </div>
</template>

<script>
import { CargillCrudMetaView } from '@cargill/shared'
import service from '../api/stuffingPortalCompaniesService'
import stuffingPortalCompaniesUsersService from '../api/stuffingPortalCompaniesUsersService'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      crudService: service,
      metadata: null,
      details: {}
    }
  },
  methods: {
    async getStuffingPortalCompaniesUsersMeta() {
      const stuffingPortalCompaniesUsersMeta =
        await stuffingPortalCompaniesUsersService.getMeta()
      stuffingPortalCompaniesUsersMeta.name = 'stuffingPortalCompaniesUsers'
      stuffingPortalCompaniesUsersMeta.masterName = 'companyId'
      stuffingPortalCompaniesUsersMeta.fields =
        stuffingPortalCompaniesUsersMeta.fields.filter(
          (field) => field.id != 'company'
        )
      stuffingPortalCompaniesUsersMeta.detailsObject = {
        service: stuffingPortalCompaniesUsersService
      }
      return stuffingPortalCompaniesUsersMeta
    },
    async getMetaDetails() {
      return await Promise.all([this.getStuffingPortalCompaniesUsersMeta()])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMeta(),
        this.getMetaDetails()
      ])
      meta.details = metaDetails
      return meta
    }
  },
  created() {
    this.getMeta().then((meta) => {
      meta.details.forEach((detail) => {
        this.details[detail.name] = detail.detailsObject
      })
      this.metadata = meta
    })
  }
}
</script>
